.layout {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 55px;
	overflow: hidden;

	.layout-overlay {
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		transition: all 0.3s;
		opacity: 0;
		pointer-events: none;
	}

	&.sidebar-expanded .layout-overlay {
		opacity: 1;
		pointer-events: all;
	}

	#main {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
	}

	#sidebar {
		background: var(--dark);
		position: fixed;
		top: 55px;
		right: -100%;
		transition: all 0.3s;
		z-index: 4;
		height: 100%;
		width: 70%;
		overflow-y: auto;
		overflow-x: hidden;

		@media (min-width: 992px) {
			position: relative;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			flex: 1;
			left: 0;
			top: 0;
			height: auto;
			width: auto;

			.layout-overlay {
				display: none;
			}
		}

		.nav {
			a {
				color: rgba(255, 255, 255, 0.7);
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				transition: all 0.2s;
			}

			a:hover {
				color: #fff;
				background: rgba(255, 255, 255, 0.1);
			}

			a.active {
				color: #fff;
				background: rgba(0, 0, 0, 0.3);
			}

			svg {
				display: inline-block;
				width: 25px;
			}
		}
	}

	&.sidebar-expanded #sidebar {
		right: 0%;
	}

	#content {
		flex: 5;
		margin: 20px 0;
		min-height: 80vh;
	}

	nav.bg-primary {
		background: var(--primary);
		background: -moz-linear-gradient(
			top,
			var(--primaryLightened) 0%,
			var(--primary) 100%
		);
		background: -webkit-linear-gradient(
			top,
			var(--primaryLightened) 0%,
			var(--primary) 100%
		);
		background: linear-gradient(
			to bottom,
			var(--primaryLightened) 0%,
			var(--primary) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--primaryLightened)', endColorstr='var(--primary)',GradientType=0 );
	}

	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
	}

	.navbar-brand {
		img {
			height: 45px;
		}

		span {
			font-weight: 500;
			font-size: 1.2rem;
		}
	}

	.user-menu {
		.dropdown-toggle {
			padding: 0;
			cursor: pointer;

			img {
				height: 45px;
			}
		}
	}

	footer {
		background: var(--dark);
		padding: 15px;
		color: #fff;
	}
}
