.layout > .navbar:first-child {
	background-image: url('../img/monuments.png');
	background-size: auto 40px;
	background-position: bottom;
	background-repeat: repeat-x;
}

body.theme-purple {
	$purple: #4d4c92;

	footer,
	.layout #sidebar {
		background: $purple;
	}

	.layout nav.bg-primary {
		background-color: #2a2952 !important;
	}
}

tr.gu-mirror {
	display: none;
}

.widget-actions .fa-bars {
	pointer-events: none;
}
