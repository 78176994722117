@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Roboto:300,400,700');


$blue:    #186ABA !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

// $primary: #186ABA !default;
$input-bg: #f9f9f9 !default;

:root {
    --primaryLightened: #{lighten($primary, 15%)};
    --primaryDarkened: #{darken($primary, 15%)};
}

@import "~bootstrap/scss/bootstrap";


body {
    background: #eee !important;
    font-family: 'Roboto', sans-serif;
    // font-size: 1.2rem;
    color: #666;
}

.table th, .table td {
    vertical-align: middle;
}

.page {
    background: #fff;
    padding: 20px;
}

@import "./layout.scss";